import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import FireworkAnimation from "./FireworkAnimation";
import logo from "./img/fs-logo.png";
import eyeLogo from "./img/eye-fill.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [questionData, setQuestionData] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(20);

  useEffect(() => {
    const confirmDelete = window.confirm(
      "Do you want to delete used questions?"
    );
    if (confirmDelete) {
      deleteUsedQuestions();
    }

    fetchCategories();
  }, []);

  const deleteUsedQuestions = async () => {
    try {
      const response = await fetch(
        "http://quiz.clouddeploy.in/api/quiz/deleteUsedQuestions",
        {
          method: "DELETE",
        }
      );
      const data = await response.json();
      if (data.status === "success") {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error deleting questions:", error);
      toast.error("An error occurred while deleting questions.");
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        "http://quiz.clouddeploy.in/api/quiz/categories"
      );
      const data = await response.json();
      setCategories(data?.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setSelectedCategoryId(categoryId);
  };

  const handleGetQuestion = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `http://quiz.clouddeploy.in/api/quiz/${selectedCategoryId}`
      );
      const questionData = await response.json();
      console.log("Fetched question:", questionData);

      if (questionData.status === "error") {
        toast.error(questionData.message);
        return;
      }

      setQuestionData(questionData.data);
      setShowAnswer(false);
      setTimer(20);
    } catch (error) {
      console.error("Error fetching question:", error);
      toast.error("An error occurred while fetching the question.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let timerInterval;
    if (questionData && timer > 0) {
      timerInterval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(timerInterval);
    }

    return () => clearInterval(timerInterval);
  }, [questionData, timer]);

  const handleRevealAnswer = () => setShowAnswer(!showAnswer);

  const handleClear = () => {
    // setSelectedCategoryId("");
    setQuestionData(null);
    setShowAnswer(false);
    setTimer(20);
  };

  return (
    <div>
      <FireworkAnimation />
      <main>
        <div className="col-md-12 col-lg-8 p-5 p-lg-0 mx-auto">
          <header>
            <div className="row align-items-center justify-content-center">
              <div className="col-md-4">
                <img src={logo} alt="logo" />
              </div>
              <div className="col-md-8">
                <div className="mainHeading mt-4 pb-4 mt-lg-0 pb-lg-0">
                  Diwali Quiz
                </div>
              </div>
            </div>
          </header>

          <div className="mt-4">
            <div className="row">
              <div className="col-md-4">
                <div className="leftWhitecontainer">
                  <h1>Choose Category</h1>
                  <select
                    onChange={handleCategoryChange}
                    value={selectedCategoryId}
                  >
                    <option value="">-- Select Category --</option>
                    {categories.map((category) => (
                      <option
                        key={category.iCategoryId}
                        value={category.iCategoryId}
                      >
                        {category.sCategoryName}
                      </option>
                    ))}
                  </select>
                  <button
                    disabled={!selectedCategoryId || questionData || loading} // Disable when loading
                    onClick={handleGetQuestion}
                  >
                    {loading ? "Loading..." : "Get Question"}{" "}
                  </button>
                </div>
              </div>
              <div className="col-md-8">
                <div className="rightwhitecontainer mt-4 mt-lg-0">
                  {questionData && (
                    <>
                      <strong>Q. {questionData.question}</strong>
                      <ul>
                        {questionData.options.map((option, index) => (
                          <li key={index}>{option}</li>
                        ))}
                      </ul>

                      <div
                        className={`timer ${
                          timer === 0 ? "time-up" : "time-running"
                        }`}
                      >
                        {timer > 0
                          ? `Time Remaining: ${timer} seconds`
                          : "Time up!"}
                      </div>

                      <button onClick={handleRevealAnswer}>
                        Reveal Answer <img src={eyeLogo} alt="eye" />
                      </button>
                      {showAnswer && (
                        <div className="correctanswer">
                          <textarea readOnly>
                            {questionData.correctAnswer}
                          </textarea>
                        </div>
                      )}

                      <div className="d-flex justify-content-end mt-3">
                        <button className="clear-button" onClick={handleClear}>
                          Clear
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
        pauseOnFocusLoss
        theme="light"
      />
    </div>
  );
}

export default App;
