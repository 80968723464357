import React, { useEffect } from "react";

const FireworkAnimation = () => {
  useEffect(() => {
    const canvas = document.getElementById("canvas");
    const ctx = canvas.getContext("2d");
    let w,
      h,
      particles = [],
      probability = 0.04;
    let xPoint, yPoint;

    function resizeCanvas() {
      w = canvas.width = window.innerWidth;
      h = canvas.height = window.innerHeight;
    }

    function updateWorld() {
      update();
      paint();
      window.requestAnimationFrame(updateWorld);
    }

    function update() {
      if (particles.length < 500 && Math.random() < probability) {
        createFirework();
      }
      particles = particles.filter((particle) => particle.move());
    }

    function paint() {
      ctx.globalCompositeOperation = "source-over";
      ctx.fillStyle = "rgba(0,0,0,0.2)";
      ctx.fillRect(0, 0, w, h);
      ctx.globalCompositeOperation = "lighter";
      particles.forEach((particle) => particle.draw(ctx));
    }

    function createFirework() {
      xPoint = Math.random() * (w - 200) + 100;
      yPoint = Math.random() * (h - 200) + 100;
      const nFire = Math.random() * 50 + 100;
      const c = `rgb(${~~(Math.random() * 200 + 55)},${~~(
        Math.random() * 200 +
        55
      )},${~~(Math.random() * 200 + 55)})`;

      for (let i = 0; i < nFire; i++) {
        const particle = new Particle(c);
        particles.push(particle);
      }
    }

    function Particle(color) {
      this.w = this.h = Math.random() * 4 + 1;
      this.x = xPoint - this.w / 2;
      this.y = yPoint - this.h / 2;
      this.vx = (Math.random() - 0.5) * 10;
      this.vy = (Math.random() - 0.5) * 10;
      this.alpha = Math.random() * 0.5 + 0.5;
      this.color = color;
    }

    Particle.prototype = {
      gravity: 0.05,
      move() {
        this.x += this.vx;
        this.vy += this.gravity;
        this.y += this.vy;
        this.alpha -= 0.01;
        return this.x > -this.w && this.x < w && this.y < h && this.alpha > 0;
      },
      draw(c) {
        c.save();
        c.beginPath();
        c.translate(this.x + this.w / 2, this.y + this.h / 2);
        c.arc(0, 0, this.w, 0, Math.PI * 2);
        c.fillStyle = this.color;
        c.globalAlpha = this.alpha;
        c.closePath();
        c.fill();
        c.restore();
      },
    };

    resizeCanvas();
    window.addEventListener("resize", resizeCanvas);
    window.requestAnimationFrame(updateWorld);

    return () => window.removeEventListener("resize", resizeCanvas);
  }, []);

  return (
    <canvas
      id="canvas"
      style={{ position: "fixed", top: 0, left: 0, zIndex: -1 }}
    />
  );
};

export default FireworkAnimation;
